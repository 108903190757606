import React from 'react';
import './App.css';
import {ProductView} from "./components/ProductView/ProductView";



export const App = () => {
    return(
         <>
             <ProductView/>
          </>

    );
}
