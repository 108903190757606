import React from "react";
import {Item} from "../commons/item";


export const Header = () => {

    return(
        <Item>
         <h1>Lista Produktów</h1>
        </Item>

    );
};
